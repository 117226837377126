<template>
    <div class="allTask">
        <div class="allTaskFilter">
            <a-date-picker @change="onChange" class="datePicker"/>
            <a-select defaultValue="1" style="width: 120px" @change="handleChange" class="select">
                <a-select-option value="1">升学类</a-select-option>
                <a-select-option value="2">考证书</a-select-option>
                <a-select-option value="3">其他类</a-select-option>
            </a-select>
        </div>
        <a-table :columns="columns" :dataSource="result" class="table" :rowKey="record => record.id">
            <template slot="operation" slot-scope="text"> 
                <div class="iconGroup hoverColor_E csP_E">
                    <a-icon type="cloud-download" style="font-size:22px" class="mr20px" v-if="text.file_url" @click="downLoadFile(text)"/>
                    <a-icon type="form" class="fs20px" @click="$router.push({path:'/home/EditTask', query: {id: text.id}})"/>
                </div>
            </template>
        </a-table>
    </div>
</template>
<script>
const columns = [
    {
        title: '课程名',
        dataIndex: 'title',
    },
    {
        title: '状态',
        dataIndex: 'status',
    },{
        title: '进程',
        dataIndex: 'total_subject',
    },
    {
        title: '操作',
       scopedSlots: { customRender: 'operation' },
    }
];

import { getTaskList } from '@/libs/api'

export default {
    name:'allTask',
    data(){
        return{
            columns,
            result: [],

        }
    },
    mounted(){
        this.GetTaskList()
    },
    methods:{
        onChange(){

        },
        handleChange(){

        },
        GetTaskList(){
            this.loading = true
            getTaskList({user_id :this.$store.getters.userInfo.id}).then(res=>{
                if(res){
                    this.result = res.list
                }
                this.loading = false
            },error=>{
                this.loading = false
                console.log(error)
            })
        },
        downLoadFile({ file_url }){
            window.location.href = file_url
        }
    }
}
</script>
<style lang="less" scoped>
.allTaskFilter{
    padding: 20px 30px;
    .datePicker{
        margin-right: 20px;
        /deep/ .ant-input{
            border-color: #5180e0;
            color: #5180e0
        }
        /deep/ .anticon-calendar{
             color: #5180e0
        }
    }
    .select{
         /deep/ .ant-select-selection{
            background: #5180e0;
            border-color: #5180e0;
            color: #fff
        }
        /deep/ .ant-select-arrow-icon{
            color: #fff
        }
    }
}

</style>