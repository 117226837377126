import service from '@/libs/request'

export const BaseUrl = 'https://theory.lanqingnet.com/'

export function Login (data) {
  return service({
    url: `${BaseUrl}/admin/login/index`,
    method: 'post',
    data
  })
}
//获取用户列表
export function getAllUsers (params) {
  return service({
    url: `${BaseUrl}/admin/User/index`,
    method: 'get',
    params
  })
}
//添加员工
export function addUser(data){
  return service({
    url: `${BaseUrl}/admin/User/add`,
    method: 'post',
    data
  })
}
//编辑员工
export function editUser(data){
  return service({
    url: `${BaseUrl}/admin/User/edit`,
    method: 'post',
    data
  })
}
//删除员工
export function delUser(params){
  return service({
    url: `${BaseUrl}/admin/User/del`,
    method: 'get',
    params
  })
}
//上传头像
export function uploadImg(formData){
  return service({
    url: `${BaseUrl}/admin/Index/upload`,
    method: 'post',
    data: formData,
  })
}
//获取分类
export function getCate(){
  return service({
    url: `${BaseUrl}/admin/User/getCate`,
    method: 'get'
  })
}
//添加任务
export function addTask(formData){
  return service({
    url:`${BaseUrl}/admin/User/addTask`,
    method:'post',
    data:formData
  })
}
//编辑任务
export function editTask(formData){
  return service({
    url:`${BaseUrl}/admin/User/editTask`,
    method:'post',
    data:formData
  })
}
//删除任务
export function delTask(data){
  return service({
    url:`${BaseUrl}/admin/User/delTask`,
    method:'post',
    data
  })
}

//任务排序
export function orderTaskSort(data){
  return service({
    url:`${BaseUrl}/admin/User/taskSort`,
    method:'post',
    data
  })
}

//任务列表
export function getTaskList(data){
  return service({
    url:`${BaseUrl}/admin/User/taskList`,
    method:'post',
    data
  })
}

//获取任务
export function getSimpleTask(data){
  return service({
    url:`${BaseUrl}/admin/User/getTask`,
    method:'post',
    data
  })
}

//提交试卷
export function addQuestions(data){
  return service({
    url:`${BaseUrl}/admin/User/addQuestions`,
    method:'post',
    data
  })
}
//获取试卷
export function getQuestions(data){
  return service({
    url:`${BaseUrl}/admin/User/getQuestions`,
    method:'post',
    data
  })
}

//设置
export function getclasses(data){
  return service({
    url:`${BaseUrl}/admin/User/getCateS`,
    method:'post',
    data
  })
}
//修改类型
export function editClasses(data){
  return service({
    url:`${BaseUrl}/admin/User/editCate`,
    method:'post',
    data
  })
}
export function addclasses(data){
  return service({
    url:`${BaseUrl}/admin/User/addCate`,
    method:'post',
    data
  })
}
//删除员工
export function delClasses(data){
  return service({
    url: `${BaseUrl}/admin/User/delCate`,
    method: 'post',
    data
  })
}

//获取模板
export function getModelDetail(data){
  return service({
    url:`${BaseUrl}/admin/User/getModelDetail`,
    method: 'post',
    data
  })
}
//保存模板
export function addModel(data){
  return service({
    url:`${BaseUrl}/admin/User/addModel`,
    method: 'post',
    data
  })
}
